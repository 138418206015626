.#{$rt-namespace}__toast {
    position: relative;
    min-height: $rt-toast-min-height;
    box-sizing: border-box;
    margin-bottom: 8px;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    max-height: $rt-toast-max-height;
    overflow: hidden;
    font-family: $rt-font-family;
    cursor: pointer;
    direction: ltr;
    margin-top: 0;
    transition: all 700ms ease;

    &-body {
        min-width: 100%;
        max-width: 100%;
    }
}

.#{$rt-namespace}--animate {
    animation-fill-mode: both;
    animation-duration: 0.7s;
}

@media #{$rt-mobile} {
    .#{$rt-namespace}__toast {
        margin-bottom: 0;
    }
}
