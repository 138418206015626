$red: #e41e42;
$red_darken: #aa001c;
$green: #00ff9c;
$green_darken: #006741;
$yellow: #F2C526;
$yellow_darken: darken(#F2C526, 10%);
$gray100: #1a2024;
$gray90: #283036;
$gray80: #383f45;
$gray70: #50565b;
$gray60: #697077;
$gray50: #868d95;
$gray40: #9fa5ad;
$gray30: #b9bfc7;
$gray20: #d5d9e0;
$gray10: #f2f4f8;
$link: #4794FF;
$control_border: #4A6282;
