.SortableTable {
    $root: &;

    height: calc(100% - 50px);
    min-height: 300px;

    &__list {
        min-height: 100%;
    }

    &__item {
        position: relative;

        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        transition: background-color 250ms ease;

        & + & {
            box-shadow: inset 0 1px 0 #34455C;
        }

        &.is-dragging {
            background: darken(#2E3E56, 10%);
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.012em;
            box-shadow: inset 0 0 0 1px #34455C;
        }

        &:hover,
        &.is-dragging {
            #{$root}__draggable {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }

    .has-dragging-row & {
        #{$root}__draggable {
            &:not(&.is-dragging) {
                transform: translateX(-100%);
                opacity: 0;
            }
        }
    }

    &__draggable {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        height: 100%;
        width: size(30px);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 250ms linear, opacity 250ms linear;

        &:not(.is-dragging) {
            transform: translateX(-100%);
            opacity: 0;
        }
    }
}


.DroppableRow {
    &.is-dragging {
        background: #f1f1f1;
    }
}