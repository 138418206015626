.ScrollbarThumb {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
    transition: background-color 300ms ease;

    &:hover {
        background-color: rgba(0, 0, 0, 0.8);

    }
}

.ScrollbarTrack {
    &.is-horizontal {
        right: 2px;
        left: 2px;
        bottom: 0;
        height: 16px !important;
        display: flex;
        align-items: center;

        .ScrollbarThumb {
            height: 6px !important;
            transition: height 300ms ease;
        }

        &:hover {
            .ScrollbarThumb {
                height: 16px !important;
            }
        }
    }

    &.is-vertical {
        right: 0;
        bottom: 2px;
        top: 2px;
        width: 24px !important;

        .ScrollbarThumb {
            width: 6px !important;
            transition: width 300ms ease;
            margin-left: auto;
            margin-right: auto;
        }

        &:hover {
            .ScrollbarThumb {
                width: 16px !important;
            }
        }
    }
}