@import './theme.styles.scss';

b {
    background: yellow;
}

a.active {
    color: $red;

    span {
        font-weight: 700;
    }

    path {
        fill: $red;
    }
}

.AppLoader {
    &-enter,
    &-appear {
        opacity: 0;
        transition: all ease-in 500ms;
    }

    &-enter-active,
    &-appear-active {
        opacity: 1;
        transition: all ease-in 500ms;
    }

    &-exit-active {
        opacity: 0;
        transition: all ease-out 500ms;
    }
}